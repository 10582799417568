import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'

import './../node_modules/bulma/css/bulma.css';
import 'bulma-timeline/dist/css/bulma-timeline.min.css';
import './../node_modules/bulma-checkradio/dist/css/bulma-checkradio.min.css'
import './../node_modules/bulma-o-steps/bulma-steps.min.css'
import './../node_modules/bulma-divider/dist/css/bulma-divider.min.css'
import './../node_modules/bulma-switch/dist/css/bulma-switch.min.css'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import vueHeadful from 'vue-headful';
Vue.component('vue-headful', vueHeadful);

Vue.config.productionTip = false
Vue.use(Vuelidate)

new Vue({
  router,
  render: h => h(App),
  data:{
    dates: null
  },
}).$mount('#app')
