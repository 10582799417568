<template>
  <div>
    <div class="box is-main-box">
      <p class="subtitle is-5 is-message">Ajude o Centro Social Paroquial da Brandoa sem custo, através da consignação do seu IRS</p>
      <img class="is-picture" src="@/assets/Consignacao.png" alt="Consignação IRS">
    </div>
  </div>
</template>

<script>
  export default {
      name: "Consignation"
  }
</script>

<style scoped>
  .is-main-box{
    width: 60%;
    margin: 3em auto 3em auto;
  }
  
  .is-message{
    text-align: center;
  }

  .is-picture{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>