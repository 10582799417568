<template>
    <div id="app">
        <vue-headful
                title="Centro Social Paroquial da Brandoa"
                description="Website oficial do Centro Social Paroquial da Brandoa"
        />
        <Nav :user-type="getUserType()"/>
        <router-view/>
        <Footer />
    </div>
</template>
<script>
    import Nav from "@/components/partials/Nav";
    import Footer from "@/components/partials/Footer";
    export default {
        name: 'app',
        components: {Footer, Nav},
        methods:{
            getUserType(){
                return localStorage.getItem('adminToken');
            }
        },
    }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
</style>