<template>
  <div class="home">
    <section class="hero is-dark">
      <div class="hero-body">
          <h2 class="title is-1">
            Centro Social Paroquial da Brandoa
          </h2>
      </div>
    </section>
    <home-info />
    <consignation />
  </div>
</template>
<script>
  import HomeInfo from "@/components/HomeInfo";
  import Consignation from '../components/Consignation.vue';
  export default {
    name: 'home',
    components: {
        HomeInfo,
        Consignation,
    }
  }
</script>
<style lang="scss" scoped>
  .hero {
    text-align: center;
    background-image: url('~@/assets/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
  }
  .hero-body .title {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 40px 0 20px 0;
  }
  .subtitle {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    font-size: 30px;
  }
  .button-block {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: absolute;
    bottom: -150px;
    .button {
      margin-right: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .welcome {
      width: 400px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .is-xl {
    font-size: 1.7rem;
  }
</style>