<template>
    <section class="central-menu">
        <div class="tile is-ancestor">
            <div class="tile is-parent has-text-centered is-3">
                <div class="tile is-child box"  v-on:click="$router.push('/quem-somos')">
                    <p class="title">Quem somos</p>
                    <p class="subtitle">Apresentação da <br/> instituição</p>
                </div>
            </div>
            <div class="tile is-parent has-text-centered is-3">
                <div class="tile is-child box"  v-on:click="$router.push('/contactos')">
                    <p class="title">Contactos</p>
                    <p class="subtitle">Os nossos contactos institucionais</p>
                </div>
            </div>
            <div class="tile is-parent has-text-centered is-3">
                <div class="tile is-child box" v-on:click="$router.push('/valencias')">
                    <p class="title">Valências</p>
                    <p class="subtitle">Descrição das <br/> nossas valências</p>
                </div>
            </div>
            <div class="tile is-parent has-text-centered is-3">
                <div class="tile is-child box"  v-on:click="$router.push('/inscricoes')">
                    <p class="title">Inscrições</p>
                    <p class="subtitle">Formulário de <br/> inscrição</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HomeInfo"
    }
</script>

<style scoped>
    @media screen and (min-width: 800px) {
        .central-menu{
            width: 60%;
            margin: 40px auto 20px auto;
        }
    }
    @media screen and (max-width: 800px) {
        .central-menu{
            width: 90%;
            margin: 40px auto 20px auto;
        }
    }
    .title{
        padding-top: 20px;
    }
    .subtitle{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .box{
        background-color: #00aeef;
    }
    .box:hover{
        background-color: #0072bc;
        cursor: pointer;
    }
</style>