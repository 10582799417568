<template>
    <footer class="footer">
        <div class="content has-text-centered">
            <p>&copy; {{year}} - Centro Social Paroquial da Brandoa. Todos os direitos reservados.</p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        data: ()=>({
            year: '',
        }),
        created: function () {
            this.year = (new Date()).getFullYear()
        }
    }
</script>

<style scoped>
.footer{
    margin-top: 40px;
    background-color: #bcbdc0;
}
</style>