import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/quem-somos',
        name: 'about',
        component: () => import('../views/About.vue')
    },
    {
        path: '/valencias',
        name: 'services',
        component: () => import('../views/Services.vue')
    },
    {
        path: '/contactos',
        name: 'contacts',
        component: () => import('../views/Contacts.vue')
    },
    {
        path: '/inscricoes',
        name: 'registration',
        component: () => import('../views/Registration.vue')
    },
    {
        path: '/recrutamento',
        name: 'recruitment',
        component: () => import('../views/Recruitment.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/Admin.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('adminToken') === null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }else {
            next()
        }
    }else {
        next()
    }
})

export default router